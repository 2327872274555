import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CustomSectionItemsWrap.scss';


const CustomSectionItemsWrap = ({ children, className }) => (
  <div
    className={classNames(
      'custom-section__items-wrap',
      className,
    )}
  >
    {children}
  </div>
);

CustomSectionItemsWrap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomSectionItemsWrap.defaultProps = {
  children: undefined,
  className: undefined,
};

export default CustomSectionItemsWrap;
