import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../../layouts/SimplePage';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import {
  CustomSection,
  CustomSectionContent,
  CustomSectionHeading,
  CustomSectionItem,
  CustomSectionItemsWrap,
} from '../../components/CustomSection';
import Calculator from '../../components/Calculator';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';

import './index.scss';


const HelpServicesPage = () => (
  <Layout
    pageWrapperClassName="help-services-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Essay Writing Help - Essay Help"
      meta={[
        {
          name: 'description',
          content: 'TakeAwayEssay.com 📝 offers reliable Essay Writing Help from experienced professionals. All papers are written according to customers’ requirements and are backed by a satisfaction guarantee. The essay help service operates 24/7 and clients’ reviews prove its quality and reliability.',
        },

        {
          property: 'og:title',
          content: 'Get Quality Essay Writing Help Online from US Experts | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'We offer quality essay help online. Place an order to get assistance with writing tasks from experienced US writers.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/help-services.html' },
      ]}
    />

    <CustomSection className="levels-section">
      <VerticalTextBlock
        sectionClassName="levels"
        dotsStyle="style-4"
        text="Levels"
      />

      <CustomSectionContent>
        <CustomSectionHeading className="levels-section__heading custom-section__heading_full">
          <h2 className="levels-section__heading__h2">
            Our services help to shorten the
            <br />
            {' '}
            path to your dream grade
          </h2>
          <p className="levels-section__heading__paragraph">
            A team of dedicated writers and ENL editors will readily provide you with expert
            assistance on any type of paper for any discipline you need. Are you studying at school,
            college, or getting your master’s degree at a university? No matter what education level
            you are at, we can complete a first-rate paper which will help you get closer to the
            grade you want to have.
          </p>
        </CustomSectionHeading>
        <CustomSectionItemsWrap className="custom-section__items-wrap_full">
          <CustomSectionItem className="levels-section__item">
            <h3 className="levels-section__item__heading">
              School
            </h3>
            <ul className="dot-list">
              <li>
                GCSE (from B to A*)
              </li>
              <li>
                NVQ (from Level 2 Pass to Distinction)
              </li>
              <li>
                A-level (from B to A*)
              </li>
              <li>
                Diploma (from HNC/HND Pass to Distinction)
              </li>
            </ul>
          </CustomSectionItem>
          <CustomSectionItem className="levels-section__item">
            <h3 className="levels-section__item__heading">
              College
            </h3>
            <ul className="dot-list">
              <li>
                Undergraduate (from 2:2 to Upper First-Class Honors)
              </li>
              <li>
                PGD (from Pass to Distinction)
              </li>
            </ul>
          </CustomSectionItem>
          <CustomSectionItem className="levels-section__item">
            <h3 className="levels-section__item__heading">
              University
            </h3>
            <ul className="dot-list">
              <li>
                Master’s degree (from Pass to Distinction)
              </li>
              <li>
                MPhil (Pass)
              </li>
              <li>
                PhD
              </li>
            </ul>
          </CustomSectionItem>
        </CustomSectionItemsWrap>
      </CustomSectionContent>
    </CustomSection>

    <CustomSection className="paper-types-section">
      <VerticalTextBlock
        sectionClassName="paper-types"
        dotsStyle="style-4"
        text="Paper types"
      />
      <CustomSectionContent>
        <CustomSectionHeading className="paper-types-section__heading">
          <h2 className="paper-types-section__heading__h2">
            Check out the most popular essay
            <br />
            writing services
            <br />
            by subject
          </h2>
        </CustomSectionHeading>
        <CustomSectionItemsWrap className="paper-types-section__items-wrap">
          <span className="paper-types-section__items-wrap__circle paper-types-section__items-wrap__circle_one" />
          <span className="paper-types-section__items-wrap__circle paper-types-section__items-wrap__circle_two" />
          <span className="paper-types-section__items-wrap__circle paper-types-section__items-wrap__circle_three" />
          <ul className="paper-types-section__list">
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/business-writing-service.html"
              >
                Business
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/law-writing-service.html"
              >
                Law
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/english-writing-service.html"
              >
                English
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/history-writing-service.html"
              >
                History
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/nursing-writing-service.html"
              >
                Nursing
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/philosophy-writing-service.html"
              >
                Philosophy
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/economics-writing-service.html"
              >
                Economics
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/psychology-writing-service.html"
              >
                Psychology
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/arts-writing-service.html"
              >
                Art
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/sociology-writing-service.html"
              >
                Sociology
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/political-science-writing-service.html"
              >
                Political Science
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/biology-writing-service.html"
              >
                Biology
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/management-writing-service.html"
              >
                Management
              </Link>
            </li>
            <li className="paper-types-section__list__item">
              <Link
                className="paper-types-section__list__item__link"
                to="/marketing-writing-service.html"
              >
                Marketing
              </Link>
            </li>
          </ul>
          <div className="paper-types-section__bottom">
            Ready to get expert academic help with your major?
            <br />
            Scroll down to calculate how much your paper would cost.
          </div>
        </CustomSectionItemsWrap>

      </CustomSectionContent>
    </CustomSection>

    <Calculator />

    <CustomSection className="basic-section">
      <VerticalTextBlock
        sectionClassName="basic"
        dotsStyle="style-4"
        text="Basic"
      />

      <CustomSectionContent>
        <CustomSectionHeading className="basic-section__heading">
          <h2 className="basic-section__heading__h2">
            This is how we make
            <br />
            great grades real
          </h2>
        </CustomSectionHeading>
        <CustomSectionItemsWrap className="basic-section__items-wrap">
          <CustomSectionItem className="basic-section__item">
            <h3 className="basic-section__item__heading">
              Plagiarism-free papers only
            </h3>
            <p className="basic-section__item__text">
              Original content is what matters most for getting a good grade. To guarantee your
              paper is authentic, we check it for plagiarism a couple of times.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="basic-section__item">
            <h3 className="basic-section__item__heading">
              Fast delivery within a specified time
            </h3>
            <p className="basic-section__item__text">
              We know how important it is to submit your essay by the due date. That’s why our
              writers always meet deadlines, and even deliver papers early.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="basic-section__item">
            <h3 className="basic-section__item__heading">
              Reasonable prices for all paper types
            </h3>
            <p className="basic-section__item__text">
              Quality papers should not cost too much. That’s why we regulate prices and keep them
              low enough thanks to no ads and low operational expenses.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="basic-section__item">
            <h3 className="basic-section__item__heading">
              Quality samples to inspire your writing
            </h3>
            <p className="basic-section__item__text">
              Every paper we write can be absolutely legally used as an example for your own
              research, or as a detailed guide to your piece of writing.
            </p>
          </CustomSectionItem>
          <div className="basic-section__items-wrap__bottom">
            We offer you a FREE title page and
            <br />
            bibliography
          </div>
        </CustomSectionItemsWrap>

      </CustomSectionContent>
    </CustomSection>

    <CustomSection className="additional-section">
      <VerticalTextBlock
        sectionClassName="additional"
        dotsStyle="style-4"
        text="Additional"
      />

      <CustomSectionContent>
        <CustomSectionHeading className="additional-section__heading custom-section__heading_full">
          <h2 className="additional-section__heading__h2">
            What else you can get from us
          </h2>
        </CustomSectionHeading>
        <CustomSectionItemsWrap className="custom-section__items-wrap_full">
          <CustomSectionItem className="additional-section__item">
            <h3 className="additional-section__item__heading">
              Choose a writer
            </h3>
            <p className="additional-section__item__text">
              You can request samples of a writer’s work to ensure he or she is experienced enough.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="additional-section__item">
            <h3 className="additional-section__item__heading">
              Source materials
            </h3>
            <p className="additional-section__item__text">
              We know how important it is to submit your essay by the due date. That’s why our
              writers always meet deadlines, and even deliver papers early.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="additional-section__item">
            <h3 className="additional-section__item__heading">
              Progressive delivery
            </h3>
            <p className="additional-section__item__text">
              Is your paper a complex one? Then we’ll send it to you for a review in parts.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="additional-section__item">
            <h3 className="additional-section__item__heading">
              Money-back guarantee
            </h3>
            <p className="additional-section__item__text">
              If you are not satisfied with the paper you get or have any other concern, we provide
              full or partial refunds, depending on the circumstances.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="additional-section__item">
            <h3 className="additional-section__item__heading">
              Free revisions
            </h3>
            <p className="additional-section__item__text">
              In case you think some part of the essay doesn’t correspond to your instructions, our
              writers will consider your comments and update the paper for free.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="additional-section__item">
            <h3 className="additional-section__item__heading">
              Strict confidentiality
            </h3>
            <p className="additional-section__item__text">
              We value your trust and cooperation. That’s why we store your personal data securely
              and never disclose your information to anyone.
            </p>
          </CustomSectionItem>
        </CustomSectionItemsWrap>
      </CustomSectionContent>
    </CustomSection>

    <CustomSection className="experts-section">
      <VerticalTextBlock
        sectionClassName="experts"
        dotsStyle="style-4"
        text="Experts"
      />

      <CustomSectionContent>
        <CustomSectionHeading className="experts-section__heading custom-section__heading_full">
          <h2 className="experts-section__heading__h2">
            Here are some of those
            <br />
            {' '}
            behind your desired grade
          </h2>
        </CustomSectionHeading>
        <CustomSectionItemsWrap className="custom-section__items-wrap_full">
          <CustomSectionItem className="experts-section__item">
            <h3 className="experts-section__item__heading">
              Leading ENL editors
            </h3>
            <p className="experts-section__item__text">
              We choose native speakers only to proofread papers in order to make the grammar
              perfect.
            </p>
            <div className="experts-section__expert">
              <div className="experts-section__expert__icon experts-section__expert__icon_olivia">
                <img
                  data-observed-image
                  data-src="/img/about-us/isabella@2x.png"
                  width={150}
                  height={150}
                  alt="Olivia Smith"
                />
              </div>
              <div className="experts-section__expert__text">
                <span>
                  Olivia Smith
                </span>
                {' '}
                Team lead
              </div>
            </div>

            <div className="experts-section__expert">
              <div className="experts-section__expert__icon experts-section__expert__icon_jack">
                <img
                  data-observed-image
                  data-src="/img/about-us/jack@2x.png"
                  width={150}
                  height={155}
                  alt="Jack Wilson"
                />
              </div>
              <div className="experts-section__expert__text">
                <span>
                  Jack Wilson
                </span>
                {' '}
                Senior editor
              </div>
            </div>

          </CustomSectionItem>
          <CustomSectionItem className="experts-section__item">
            <h3 className="experts-section__item__heading">
              Quality assurance team
            </h3>
            <p className="experts-section__item__text">
              After your paper is ready, our experts check it for its compliance with educational
              standards and your personal instructions.
            </p>
            <div className="experts-section__expert">
              <div className="experts-section__expert__icon experts-section__expert__icon_lewis">
                <img
                  data-observed-image
                  data-src="/img/about-us/mathew@2x.png"
                  width={150}
                  height={155}
                  alt="Lewis Wood"
                />
              </div>
              <div className="experts-section__expert__text">
                <span>
                  Lewis Wood
                </span>
                {' '}
                Head of QA
              </div>
            </div>

            <div className="experts-section__expert">
              <div className="experts-section__expert__icon experts-section__expert__icon_emily">
                <img
                  data-observed-image
                  data-src="/img/about-us/mia@2x.png"
                  width={148}
                  height={146}
                  alt="Emily Phillips"
                />
              </div>
              <div className="experts-section__expert__text">
                <span>
                  Emily Phillips
                </span>
                {' '}
                Senior proofreader
              </div>
            </div>
          </CustomSectionItem>

          <CustomSectionItem className="experts-section__item experts-section__item_fullwidth">
            <h3 className="experts-section__item__heading">
              Top writers
            </h3>
            <p className="experts-section__item__text">
              Each of our writers is thoroughly selected and passes several tests
              <br className="experts-section__item__br" />
              {' '}
              before being hired.
            </p>
            <div className="experts-section__top-experts-wrap">
              <div className="experts-section__expert experts-section__expert_top">
                <div className="experts-section__expert-inner">
                  <div className="experts-section__expert__icon experts-section__expert__icon_ian">
                    <img
                      data-observed-image
                      data-src="/img/about-us/photo-ian@2x.png"
                      width={155}
                      height={146}
                      alt="Ian Clarke"
                    />
                  </div>
                  <div className="experts-section__expert__text">
                    <span>
                      Ian Clarke
                    </span>
                    {' '}
                    Computer science
                  </div>
                </div>
              </div>

              <div className="experts-section__expert experts-section__expert_top">
                <div className="experts-section__expert-inner">
                  <div className="experts-section__expert__icon experts-section__expert__icon_ashley">
                    <img
                      data-observed-image
                      data-src="/img/about-us/photo-ashley@2x.png"
                      width={148}
                      height={146}
                      alt="Ashley Murphy"
                    />
                  </div>
                  <div className="experts-section__expert__text">
                    <span>
                      Ashley Murphy
                    </span>
                    {' '}
                    Medicine and nursing
                  </div>
                </div>
              </div>
              <div className="experts-section__expert experts-section__expert_top">
                <div className="experts-section__expert-inner">
                  <div className="experts-section__expert__icon experts-section__expert__icon_keith">
                    <img
                      data-observed-image
                      data-src="/img/about-us/photo-keith@2x.png"
                      width={157}
                      height={158}
                      alt="Keith Hughes"
                    />
                  </div>
                  <div className="experts-section__expert__text">
                    <span>
                      Keith Hughes
                    </span>
                    {' '}
                    Business and management
                  </div>
                </div>
              </div>

              <div className="experts-section__expert experts-section__expert_top">
                <div className="experts-section__expert-inner">
                  <div className="experts-section__expert__icon experts-section__expert__icon_heather">
                    <img
                      data-observed-image
                      data-src="/img/about-us/photo-heater@2x.png"
                      width={148}
                      height={146}
                      alt="Heather Stewart"
                    />
                  </div>
                  <div className="experts-section__expert__text">
                    <span>
                      Heather Stewart
                    </span>
                    {' '}
                    Accounting and finance
                  </div>
                </div>
              </div>

            </div>
          </CustomSectionItem>

        </CustomSectionItemsWrap>
      </CustomSectionContent>
    </CustomSection>


    <SeoTextSection customVerticalText="Essay Help">
      <SeoTextHeading>
        Essay help: essay writing help
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            It&apos;s not a secret that a great majority of students have difficulties completing
            their writing tasks. This results in low grades and poor academic progress. According
            to the National Assessment of Educational Progress (NAEP), only 3% of students performed
            at an advanced level, while 79% of them could reach only the basic level of writing
            in 2011. What does this mean? On average, nearly every student needs
            {' '}
            <em>
              assistance
            </em>
            {' '}
            with compiling creative content or doing efficient research.
          </p>
          <p>
            Hopefully, this article will explain why using custom academic writing assistance can
            be a good and effective way to cope with your homework. It may also demonstrate how to
            choose a writing service you can trust.
          </p>

          <h2 className="h3">
            Why is using paper writing help a good idea?
          </h2>
          <p>
            Can you remember the last time you were preparing an assignment? How much time did
            it take? Was it easy to find needed information and then complete a stunning
            writing piece? Now, imagine that the whole assignment could be done within
            just 8-12 hours. And these are not just empty promises. It won&apos;t take a qualified
            academic specialist much time to complete your task even in the most complex
            disciplines, such as business, chemistry, engineering, history, management,
            programming, etc.
          </p>
          <p>
            Besides, most academic websites deliver papers for different educational levels.
            Here are the types of writing services you may come across:
          </p>
          <ul className="dot-list">
            <li>
              <Link
                to="/college-writing-service.html"
                className="seo-text-section__link"
              >
                College essay writing service
              </Link>
            </li>
            <li>
              <Link
                to="/mba-writing-service.html"
                className="seo-text-section__link"
              >
                MBA essay writing service
              </Link>
            </li>
            <li>
              <Link
                to="/academic-writing-service.html"
                className="seo-text-section__link"
              >
                Academic essay writing service
              </Link>
            </li>
            <li>
              <Link
                to="/high-school-writing-service.html"
                className="seo-text-section__link"
              >
                High-school essay writing service
              </Link>
            </li>
            <li>
              <Link
                to="/masters-writing-service.html"
                className="seo-text-section__link"
              >
                Master’s essay writing service
              </Link>
            </li>
            <li>
              <Link
                to="/phd-writing-service.html"
                className="seo-text-section__link"
              >
                PhD essay writing service
              </Link>
            </li>
            <li>
              <Link
                to="/undergraduate-writing-service.html"
                className="seo-text-section__link"
              >
                Undergraduate essay writing service
              </Link>
            </li>
            <li>
              <Link
                to="/buy-college.html"
                className="seo-text-section__link"
              >
                Buy College Essays
              </Link>
            </li>
          </ul>
          <p>
            Thus, if you need custom help with any paper, you may look for an online service
            and choose the one that suits you the most. Usually, such websites can provide
            custom assistance with nearly any kind of paper. This could be an essay or a
            book report, a project or a thesis, etc. The most important thing is that with
            the help of a proficient expert, you will get the task completed at a high
            level. For instance, the most popular essay types students order from paper
            writing services are as follows:
          </p>
          <ul className="dot-list">
            <li>
              <Link
                to="/admission-writing-service.html"
                className="seo-text-section__link"
              >
                Admission essay
              </Link>
            </li>
            <li>
              <Link
                to="/application-writing-service.html"
                className="seo-text-section__link"
              >
                Application essay
              </Link>
            </li>
            <li>
              <Link
                to="/argumentative-writing-service.html"
                className="seo-text-section__link"
              >
                Argumentative essay
              </Link>
            </li>
            <li>
              <Link
                to="/critical-writing-service.html"
                className="seo-text-section__link"
              >
                Critical essay
              </Link>
            </li>
            <li>
              <Link
                to="/narrative-writing-service.html"
                className="seo-text-section__link"
              >
                Narrative essay
              </Link>
            </li>
            <li>
              <Link
                to="/personal-statement-writing-service.html"
                className="seo-text-section__link"
              >
                Personal statement
              </Link>
            </li>

            <li>
              <Link
                to="/buy-personal-statement.html"
                className="seo-text-section__link"
              >
                Buy Personal Statement Online
              </Link>
            </li>
            <li>
              <Link
                to="/buy-admission.html"
                className="seo-text-section__link"
              >
                Buy Admission Essay
              </Link>
            </li>
          </ul>
          <p>
            A knowledgeable academic writer will deliver high quality writing services and solve a
            great many of your troubles with homework at once. Thus, he or she will:
          </p>
          <ul className="dot-list">
            <li>
              Analyze the topic and suggest a structure for the paper
            </li>
            <li>
              Make a detailed research of the subject
            </li>
            <li>
              Write original content
            </li>
            <li>
              Provide a list of references
            </li>
            <li>
              Check the paper for plagiarism
            </li>
            <li>
              Deliver the finished order by the fixed deadline
            </li>
          </ul>
          <p>
            In such a way, you will get reliable essay help online according to your instructions.
            You just have to select a trustworthy website and submit your order to it.
          </p>

          <h2 className="h3">
            How to choose credible essay writing assistance?
          </h2>
          <p>
            However, if you want to get skillful help and rewarding results, make an inquiry
            with a dependable academic company. When you take a closer look at the website you
            are going to use, you will notice the features that differentiate a reliable academic
            company from those which sound convincing, yet write bad samples.
          </p>
          <p>
            For this reason, you should always consider the content of the website carefully. It
            usually indicates whether the service is professional or not. Every efficient service
            always provides certain guarantees. Generally, they are the following:
          </p>
          <ul className="dot-list">
            <li>
              <strong>
                Plagiarism-free guarantee.
              </strong>
              {' '}
              A trustworthy company cares about the quality of the content it delivers. Thus, it
              ensures that its customers get only papers written from scratch and checks content
              with the help of special detection software.
            </li>
            <li>
              <strong>
                Confidentiality guarantee.
              </strong>
              {' '}
              A professional writing service always keeps the personal information of its users
              confidential. This means that neither the name or the email address of the user will
              ever be disclosed to a third party.
            </li>
            <li>
              <strong>
                Free revision guarantee.
              </strong>
              {' '}
              It may happen that something in the finished paper doesn&apos;t meet your
              requirements. In this case, a credible website offers a chance to revise a completed
              assignment for you. The writer will make the necessary changes according to your
              expectations.
            </li>
            <li>
              <strong>
                Money back guarantee.
              </strong>
              {' '}
              When you use essay help from a solid and secure writing company, you can request your
              money back if for some reason the writer&apos;s work doesn&apos;t satisfy your needs.
            </li>
          </ul>
          <p>
            Therefore, when looking for{' '}
            <Link to="/for-sale.html" className="seo-text-section__link">
              essays for sale
            </Link>
            , make sure that the company not only provides high-quality academic assistance, but
            also is reliable and safe. Also, try to check if the{' '}
            <Link to="/custom-writing-service.html" className="seo-text-section__link">
              custom essay service
            </Link>{' '}
            has{' '}
            <Link to="/professional-writers.html" className="seo-text-section__link">
              professional essay writers
            </Link>{' '}
            only, who have enough experience and can deliver a great paper, written from scratch.
          </p>

          <p>
            Are you ready to try that combination right now? We know how to deal with your requests
            like, “
            <Link
              to="/write-for-me.html"
              className="seo-text-section__link"
            >
              Write my essay
            </Link>
            {' '}
            for me”, “Can I
            {' '}
            <Link
              to="/buy-online.html"
              className="seo-text-section__link"
            >
              buy essay online
            </Link>
            {' '}
            from you”, or, “Is it reasonable to
            {' '}
            <Link
              to="/pay-for-writing.html"
              className="seo-text-section__link"
            >
              pay for essay
            </Link>
            {' '}
            instead of writing it myself?” Place an order with us and get expert help with your
            paper from professional US writers!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA>
      Want to get a high-quality essay at a low cost?
      <br />
      {' '}
      Then you’re just one step away.
    </CTA>

  </Layout>
);

export default HelpServicesPage;
