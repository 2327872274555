import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const CustomSectionContent = ({ children, className, hasStickyElement }) => (
  <div
    className={classNames(
      'custom-section__content',
      !hasStickyElement && 'custom-section__content_static',
      className,
    )}
  >
    {children}
  </div>
);

CustomSectionContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasStickyElement: PropTypes.bool,
};

CustomSectionContent.defaultProps = {
  children: undefined,
  className: undefined,
  hasStickyElement: false,
};

export default CustomSectionContent;
