import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CustomSectionStickyItemsWrap.scss';


const CustomSectionStickyItemsWrap = ({ children, className }) => (
  <div
    className={classNames(
      'custom-section__sticky-items-wrap',
      className,
    )}
  >
    {children}
  </div>
);

CustomSectionStickyItemsWrap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomSectionStickyItemsWrap.defaultProps = {
  children: undefined,
  className: undefined,
};

export default CustomSectionStickyItemsWrap;
